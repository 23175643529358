var pawLibrary = {
	map: false,
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	markers: [],
	dropdownMenus: function () {
		$('.parent > a').on('click', function (e) {
			var selected = $(this);
			var id = selected.attr('id');
			if (id != '') id = id.replace('showMenu', '');


			if ($('#subMenu' + id).length > 0) {
				e.preventDefault();
			}

			$('ul#nav li').removeClass('highlight');

			// Show
			if (selected.parent().hasClass('highlight') == true) {
				// Hide
				selected.parent().removeClass('highlight');
				$('.parent-sub').hide();
				$('.dropdown-menu').hide();
			} else {
				// Show
				selected.parent().addClass('highlight');
				$('.parent-sub').hide();
				$('.parent li').removeClass('highlight');
				$('#subMenu' + id).fadeIn();
				//$('#showMenu' + id).addClass('highlight');
			}
		});

		// hide mega menu
		$(document).click(function (e) {
			if ($('#showMenu7').hasClass('highlight') == true) {
				var $container = $("header.page-header .top");
				if (!$container.is(e.target) && $container.has(e.target).length === 0) {
					$('#showMenu7').trigger('click');
				}
			}
		});
	},
	validateForm: function () {
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if (name_txt.val().length < 3) {
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if (!filter.test(email_txt.val())) {
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if (message_txt.val().length < 3) {
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function (elemId, username) {

		$.ajax({
			type: 'json',
			url: '../ajax/getTwitter.inc.php?username=' + username,
			beforeSend: function () {
				$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="' + username + '" class="loader" />');
			},
			success: function (data) {
				var img = data[0].image;
				$('#comment' + elemId + ' .img-wrap').html('<img src="' + img + '" alt="' + username + '" class="profile" />');
			}
		});

	},

	generateMap: function (elem, lat, lng, zoomLevel) {
		var myLatlng = new google.maps.LatLng(lat, lng);
		var myOptions = {
			zoom: zoomLevel,
			center: myLatlng,
			scrollwheel: false,
			draggable: true,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			styles: [{ "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] }, { "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }] }, { "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }] }]
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function (lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat, lng);
		var contentString = '<div id="mapContent" class="infoMarker">' +
			'<p><strong>' + location_title + '</strong></p>' +
			'<p>' + location_add + '</p>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function () {
			infowindow.open(this.map, marker);
		});

		google.maps.event.addDomListener(window, 'resize', function () {
			//var center = map.getCenter();
			//map.setCenter(center);
		});
	},
	centerMap: function () {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap: function (elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat, lng);
		var myOptions = {
			zoom: 14,
			center: myLatlng,
			scrollwheel: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			draggable: drag,
			styles: [{ "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] }, { "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }] }, { "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }] }]
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">' +
			'<p><strong>' + location_title + '</strong></p>' +
			'<p>' + location_add + '</p>' +
			'<a class="btn" href="https://maps.google.co.uk/maps?daddr=' + encodeURI(location_add) + '">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if (drag == true) {
			google.maps.event.addListener(marker, 'dragend', function () {
				var point = marker.position;
				if (lat_txt) lat_txt.value = point.$a;
				if (long_txt) long_txt.value = point.ab;

			});
		}

		google.maps.event.addListener(marker, 'click', function () {
			infowindow.open(map, marker);
		});

		google.maps.event.addDomListener(window, 'resize', function () {
			//var center = map.getCenter();
			//map.setCenter(center);
		});

	},

	setMapByGeocode: function (elemId, address, zoomlevel) {

		var mapOptions = {
			zoom: zoomlevel,
			scrollwheel: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			draggable: false,
			styles: [{ "featureType": "all", "elementType": "geometry.fill", "stylers": [{ "weight": "2.00" }] }, { "featureType": "all", "elementType": "geometry.stroke", "stylers": [{ "color": "#9c9c9c" }] }, { "featureType": "all", "elementType": "labels.text", "stylers": [{ "visibility": "on" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "landscape", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "landscape.man_made", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] }, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "road", "elementType": "labels.text.fill", "stylers": [{ "color": "#7b7b7b" }] }, { "featureType": "road", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#46bcec" }, { "visibility": "on" }] }, { "featureType": "water", "elementType": "geometry.fill", "stylers": [{ "color": "#c8d7d4" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#070707" }] }, { "featureType": "water", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }]
		}

		var contentString = '<div id="mapContent">' +
			'<p>' + address + '</p>' +
			'<a href="https://maps.google.co.uk/maps?daddr=' + encodeURI(address) + '">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode({
			'address': address
		}, function (results, status) {
			if (status == google.maps.GeocoderStatus.OK) {
				map.setCenter(results[0].geometry.location);
				var marker = new google.maps.Marker({
					map: map,
					position: results[0].geometry.location
				});

				google.maps.event.addListener(marker, 'click', function () {
					infowindow.open(map, marker);
				});

			}
		}
		);

	},

	mobileNav: function () {
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var secondMenu = mobileNavItems.find('ul.second');
		if (mobileBtn.length > 0) {
			// Load Menu
			mobileBtn.click(function () {
				if (mobileBtn.hasClass('menu-open') == true) {
					mobileNavItems.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					mobileNavItems.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function (e) {
				var clicked = e.currentTarget.id.replace("showMenu", "");
				var target = mobileNavItems.find('#subMenu' + clicked);
				var status = target.hasClass("open");

				if (status == false) {
					secondMenu.slideUp(function () {
						secondMenu.removeClass('open');
					});

					target.slideDown(function () {
						target.addClass('open');
					});
				} else {
					target.slideUp(function () {
						target.removeClass('open');
					});
				}
			});
			$('#close-btn').click(function () {
				mobileBtn.trigger('click');
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			top: 0
		});

	},

	dateSelector: function () {
		if ($('.datepicker').length > 0) {
			var d = new Date();
			var today = d.getDate() + " " + this.monthNames[d.getMonth()] + " " + d.getFullYear().toString().substr(2, 2);
			$(".datepicker").minical({
				trigger: '.datepicker',
				from: new Date(),
				initialize_with_date: false
			}).val('Starting ' + today);
		}
	},

	inlineEnquiries: function () {
		var btn = $('#request');

		if (btn.length > 0) {
			btn.fancybox({
				afterLoad: function () {
					var url = window.location.href;
					url = url.split('#');
					url = url[0];

					var tmp = $('#pageTitle');
					if (tmp.length > 0) {
						$('#title_txt').val(tmp.html());
					}

					$('#url_txt').val(url);
				},
				afterShow: function (instance, slide) {
					var loading = $('#loader');
					var form = $('#enquiry-frm');

					var name_txt = form.find('#name_txt');
					var email_txt = form.find('#email_txt');
					var msg_txt = form.find('#msg_txt');

					var name_err = form.find('#error-name');
					var email_err = form.find('#error-email');
					var msg_err = form.find('#error-msg');

					$('#send_msg_btn').click(function (e) {
						e.preventDefault();
						var errors = false;

						// validation
						if (name_txt.val() == '') {

							name_txt.addClass('error-input');
							name_err.fadeIn();
							errors = true;
						} else {
							name_txt.removeClass('error-input');
							name_err.hide();
						}
						if (!pawLibrary.isValidEmailAddress(email_txt.val())) {
							email_txt.addClass('error-input');
							email_err.fadeIn();
							errors = true;
						} else {
							email_txt.removeClass('error-input');
							email_err.hide();
						}
						if (msg_txt.val() == '') {
							msg_txt.addClass('error-input');
							msg_err.fadeIn();
							errors = true;
						} else {
							msg_txt.removeClass('error-input');
							msg_err.hide();
						}

						// sent
						if (errors == false) {
							loading.removeClass('hide').fadeIn();
							form.hide();
							var formData = $('#enquiry-frm').serialize();

							$.ajax({
								url: './ajax/sendEnquiry.inc.php?init=sendEnquiry',
								data: formData,
								method: 'POST'
							}).done(function (response) {
								$('#loader').html("Your message has been sent");
							});

						}
					});
				}
			});
		}
	},

	jumpToLinks: function () {
		$('a[href^=#]').click(function (e) {
			var name = $(this).attr('href').substr(1);
			var pos = $('#' + name).offset();
			$('body').animate({ scrollTop: pos.top });
			e.preventDefault();
		});
	},

	updateProductURL: function (url) {
		window.history.pushState(null, null, url);
	},

	isValidEmailAddress: function (emailAddress) {
		var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
		return pattern.test(emailAddress);
	},

	menuNavigation: function () {
		var cats = $('#cat-selector span');
		var menus = $('.menu-category-wrap');
		var body = $('body');
		var defaultImg = body.attr('style');

		// SET FIRST CHILD
		var first = $(cats[0]);
		first.addClass('active');
		menus.hide();
		$(menus[0]).show();

		var bck = first.attr('data-img');
		if (bck != '') {
			body.css('background-image', 'url(' + bck + ')');
		}

		cats.click(function () {
			var selected = $(this);
			cats.removeClass('active');
			selected.addClass('active');

			var menuId = selected.attr('data-cat-id');
			menus.hide();
			var target = $('.menu-category-wrap[data-menu-id=' + menuId + ']');
			target.fadeIn();
			var bck = selected.attr('data-img');
			if (bck == '') {
				body.css("background-image", defaultImg);
			} else {
				body.css('background-image', 'url(' + bck + ')');
			}
		});

		/// MBOILE
		var mobile_menu = $('#mobile-cat-selector select');
		mobile_menu.change(function () {
			var selected = $(this).val();
			menus.hide();
			var target = $('.menu-category-wrap[data-menu-id=' + selected + ']');
			target.fadeIn();
			cats.removeClass('active');
			$('.cat-selector[data-cat-id=' + selected + ']').addClass('active');
			var bck = $('.cat-selector[data-cat-id=' + selected + ']').attr('data-img');
			if ((bck == '') || (bck == undefined)) {
				body.css("background-image", defaultImg);
			} else {
				body.css('background-image', 'url(' + bck + ')');
			}
		});
	},

	init: function () {
		pawLibrary.mobileNav();
		pawLibrary.inlineEnquiries();
		pawLibrary.jumpToLinks();

		if ($('#cat-selector').length > 0) {
			pawLibrary.menuNavigation();
		}
	}

}


$(window).load(function () {
	pawLibrary.init();
});